<template>
  <main class="form-signin text-center">
    <div>
      
      <h1 class="h3 mb-3 fw-normal">From</h1>
      <div class="alert alert-danger" role="alert" v-if="error">
        {{error}}
      </div>

      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          placeholder="Login"
          v-model="login" 
        />
        <label for="floatingInput">Login</label>
      </div>
      <div class="form-floating my-2">
        <input
          type="password"
          class="form-control"
          placeholder="Password"
          v-model="password"
        />
        <label for="floatingPassword">Password</label>
      </div>

     
      <a class="w-100 btn btn-lg btn-primary" @click="logIn()">
        Submit
      </a>
    </div>
  </main>
</template>

<script>
export default {
  name:'Login',
  data() {
    return {
      login:"",
      password:"",
      error:"",
    };
  },
  methods: {
    logIn() {
        this.HTTP.get('/sanctum/csrf-cookie').then(response => {
            this.HTTP.post("/login",{
                login:this.login,
                password:this.password,
            })
            .then((response) => {
                localStorage.setItem('token',response.config.headers['X-XSRF-TOKEN']);
                localStorage.setItem('user',JSON.stringify(response.data.user));
                this.$emit('loginUpdate');
                this.$router.push('/');
            })
            .catch((error) => {
                this.error=error.message;
                console.log(error);
            });
        });
        
    },
  },
  
};
</script>

<style>


.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}



</style>