<template>
  <div class="container">

    <div
      class="modal fade"
      id="showProductModal"
      tabindex="-1"
      aria-labelledby="showProductModalLabel"
      aria-hidden="true"
      ref="showProductModal"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="showProductModalLabel">
              Просмотр товара
            </h5>
            <a class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"
              >X</a
            >
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12 col-md-6">
                <label class="form-label mt-2">Название товара</label>
                <h5>{{showProduct.name}}</h5>
                
                <label class="form-label mt-2">SKU товара</label>
                <h5>{{showProduct.sku}}</h5>
                
              </div>
              <div class="col-12 col-md-6">
                <label class="form-label mt-2">Артикул товара</label>
                <h5>{{showProduct.articul}}</h5>
                
                <div class="form-group">
                  <label class="form-label mt-2">Картинка товара</label>
                  <div class="row my-1" v-if="showProduct.image">
                    <div class="col-4 position-relative">
                      <div
                        class="position-absolute"
                        style="left: 25px; top: 10px"
                      >
                        
                      </div>
                      <div
                        class="prod-photo border-1 border rounded"
                        :style="{
                          'background-image': `url('` + showProduct.image + `')`,
                        }"
                      ></div>
                    </div>
                  </div>
                  <br />
                  
                </div>
              </div>
              <div class="col-12">
                <label class="form-label mt-2">Описание товара</label>
                <p>{{showProduct.description}}</p>
                
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Закрыть
            </button>
            
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-8">
        <div class="showcase-photo" id="showCase" style=" overflow: hidden;" :style="[
          shownShowCase.image?`background-image:url('` +RESURCE_URL+ shownShowCase.image + `')`:``
      ]">
          <div class="marker" v-for="(link, index) in shownShowCase.child_links" :key="index" :style="{
            top: link.ycord * mainDivY - 20 + 'px',
            left: link.xcord * mainDivX - 20 + 'px',
          }" @click="goToMarker(index,link.type,link.child_id)"
            :data-bs-toggle="link.type=='product'?'modal':null"
            :data-bs-target="link.type=='product'?'#showProductModal':null">
            <span>{{ index + 1 }}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
          <ol class="breadcrumb">


            <template v-for="(elem, index) in showcase_history" :key="index">
              <li class="breadcrumb-item active" aria-current="page" v-if="index == (showcase_history.length - 1)">
                {{ elem.name }}</li>
              <li class="breadcrumb-item" @click="goToShowCase(index, elem.id)" v-else><a href="#">{{ elem.name }}</a>
              </li>
            </template>
          </ol>
        </nav>
        <ol class="list-group list-group-numbered">
          <li v-for="child in child_showcase" :key="child.id" style="cursor: pointer;" @click="initShowcase(child.id)"
            class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold">{{ child.name }}</div>
              <p>Описание: {{ child.description?child.description.substr(0,15):'' }}</p>

            </div>
            <span class="badge bg-primary rounded-pill">{{ child.child_links_count }}</span>
          </li>

        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainComponent",
  data() {
    return {
      errors: [],
      dots: [],
      mainDivX: 0,
      mainDivY: 0,
      showcase_history: [],
      child_showcase: [],
      shownShowCase: {},
      showProduct:{},
    };
  },
  methods: {
    async goToMarker(index,type,id){
      if(type=="showcase"){
        this.initShowcase(id);
      }else{
        await this.API.get("/products/" + id)
        .then((response) => {
          response.data.product.image=this.RESURCE_URL+response.data.product.image;
          this.showProduct = response.data.product;
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.modalErrors = error.response.data.errors;
          }
        });
      }
    },
    async goToShowCase(index, id) {
      this.showcase_history.splice(index + 1, this.showcase_history.length - index + 1);
      await this.API.get("/mainshowcases", {
        params: { parent_id: id },
      })
        .then((response) => {
          this.child_showcase = response.data.showcases;
          if (id != 0) {
            this.API.get("/showcases/" + id)
              .then((response) => {
                this.shownShowCase=response.data.showcase;
              })
              .catch((error) => {
                if (error.response.data.errors) {
                  this.errors = error.response.data.errors;
                }
              });
          }else{
            this.shownShowCase={};
          }
        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
        });

    },
    async initShowcase(id = 0) {
      this.errors = [];
      await this.API.get("/mainshowcases", {
        params: { parent_id: id },
      })
        .then((response) => {
          this.child_showcase = response.data.showcases;
          if(id!=0){
            this.getShowcase(id);
          }

        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
        });

    },
    async getShowcase(id) {
      this.errors = [];

      await this.API.get("/showcases/" + id)
        .then((response) => {
          this.shownShowCase=response.data.showcase;

          this.showcase_history.push({
            name: response.data.showcase.name,
            id: response.data.showcase.id
          });

        })
        .catch((error) => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
        });


    },
    setMainCord({ width, height }) {
      this.mainDivX = document.getElementById('showCase').clientWidth;
      this.mainDivY = document.getElementById('showCase').clientHeight;
    }
  },
  computed: {
    normalizeDots() {
      return this.dots.map(function (dot) {
        return {
          'x': (dot.x - 2) / document.getElementById('showCase').clientWidth,
          'y': (dot.y - 2) / document.getElementById('showCase').clientHeight
        }
      });
    },

  },
  mounted() {
    this.initShowcase();
    window.addEventListener('resize', this.setMainCord);
    this.mainDivX = document.getElementById('showCase').clientWidth;
    this.mainDivY = document.getElementById('showCase').clientHeight;

    this.showcase_history.push({
      name: "Магазин",
      id: 0
    });
    var app=this;
    this.$refs['showProductModal'].addEventListener("hidden.bs.modal", function(){
      app.showProduct={};
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.setMainCord);
  },
};
</script>

<style scoped>
.showcase-photo {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background-position: center center;
  background-size: cover;
  border: 1px solid black;

}

.prod-photo {
  height: 300px !important;
  width: 300px !important;
  background-position: center center;
  background-size: cover;
}


.marker {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: white;
  box-shadow: 0px 0px 0px 5px black;
  border-radius: 5px;
  z-index: 1;
  text-align: center;
  cursor: pointer;
}
</style>

